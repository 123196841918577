@import url("https://cdn.eds.equinor.com/font/equinor-font.css");
@import "./variables.scss";

body {
  margin: 0;
  font-family: "Equinor", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.faded {
  opacity: 0.6;
}

.semiFaded {
  opacity: 0.8;
}

label[disabled] {
  opacity: 0.6;
}

.unpresent {
  opacity: 0.4;
  filter: grayscale(1);
}

.spaces-resize-handle {
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.value-changed {
  text-decoration: underline;
  text-underline-offset: 1px;
}

input[readonly]:focus {
  outline: none;
}

.inputError {
  select,
  input {
    outline: 1px solid rgba(235, 0, 0, 1) !important;
    &:focus {
      outline: 2px solid rgba(179, 13, 47, 1) !important;
    }
  }
}

.inputWarning {
  select,
  input {
    outline: 1px solid var(--warningText) !important;
    &:focus {
      outline: 2px solid var(--warningText) !important;
    }
  }
}

.disable-link {
  pointer-events: none;
}

.grayscale {
  filter: grayscale(100%);
}

@import "./tiny.scss";
